<template>
  <page-header-wrapper>
    <template slot="extra">
      <workbench-tab @change="getTabId" v-model="active"></workbench-tab>
    </template>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper" v-action:query>
        <a-form layout="inline">
          <a-row :gutter="6">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="5">
              <a-form-item label="时间筛选：">
                <a-range-picker @change="getDate" v-model="queryParam.time" style="width:220px" />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="4">
              <a-form-item label="关键词">
                <a-input style="max-width: 220px;" placeholder="请输入" v-model="queryParam.keyword" />
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" :xxl="3">
              <a-form-item label="来源">
                <a-select v-model="queryParam.dataSource" placeholder="请选择" default-value="0" style="width:100px">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="3">网厅</a-select-option>
                  <a-select-option value="2">微信</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="5" :xxl="3">
              <a-form-item label="状态">
                <a-select v-model="queryParam.auditStatus" placeholder="请选择" default-value="0" @change="getStatus" style="width:100px">
                  <a-select-option :value="index" v-for="(item,index) in statusMap" :key="index">{{item.text}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="5" :xxl="4">
              <a-form-item label="领取方式">
                <a-select v-model="queryParam.type" placeholder="请选择" default-value="0" @change="getTpye"
                  style="width:100px">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">线下</a-select-option>
                  <a-select-option value="2">快递</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :xs="24" :sm="24" :md="10" :lg="8" :xl="14" :xxl="5"
              style="display: flex;justify-content: space-between;">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
              </span>
              <div class="btn_excel" @click="exportData"><img src="../../assets/images/excel.png" alt="">导出</div>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- <div style="display: flex;justify-content: flex-end;margin-bottom: 10px;">
        <div class="btn_excel" @click="exportData"><img src="../../assets/images/excel.png" alt="">导出</div>
      </div> -->
      <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData"
        showPagination="auto" :scroll="{x:'1500px'}" :pageNum="pageNum">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="source" slot-scope="data_source">
          <a-tag :color="getSource(data_source).color">{{getSource(data_source).name}}</a-tag>
        </span>
        <span slot="status" slot-scope="audit_status">
          <a-badge :class="'badge'+audit_status" :status="audit_status | statusTypeFilter" :text="audit_status | statusFilter" />
        </span>
        <span slot="business_code" slot-scope="business_code">
          {{business_code || '-'}}
        </span>
        <span slot="name" slot-scope="name">
          {{name || '-'}}
        </span>
        <span slot="phone" slot-scope="phone">
          {{phone || '-'}}
        </span>
        <span slot="attachment" slot-scope="record">
          <template v-if="record.attachment&&(record.attachment['0-1']||record.attachment['0-2']||record.attachment['0-3']||record.attachment['0-4']||record.attachment['0-5'])">
            <span><img :src="getImgUrl(getUrl(record.attachment))" alt="" width="60px" :preview="record.id" style="cursor:pointer;max-height: 40px;"></span>
            <span v-if="record.attachment&&record.attachment['0-2']"><img :src="getImgUrl(record.attachment['0-2'])" alt="" width="60px" :preview="record.id" style="cursor:pointer;max-height: 40px;display: none;"></span>
            <span v-if="record.attachment&&record.attachment['0-3']"><img :src="getImgUrl(record.attachment['0-3'])" alt="" width="60px" :preview="record.id" style="cursor:pointer;max-height: 40px;display: none;"></span>
            <span v-if="record.attachment&&record.attachment['0-4']"><img :src="getImgUrl(record.attachment['0-4'])" alt="" width="60px" :preview="record.id" style="cursor:pointer;max-height: 40px;display: none;"></span>
            <span v-if="record.attachment&&record.attachment['0-5']"><img :src="getImgUrl(record.attachment['0-5'])" alt="" width="60px" :preview="record.id" style="cursor:pointer;max-height: 40px;display: none;"></span>
          </template>
          <template v-else>-</template>
        </span>
        <span slot="method" slot-scope="record">
          <span v-if="record.type==1">线下自取</span>
          <span v-else>邮政到付 <span v-if="record.express_number">({{record.express_number}})</span></span>
        </span>
        <span slot="info" slot-scope="record">
          <div v-if="record.data&&record.data.length>0">
            {{record.data[0].customer_name}} ({{record.data[0].customer_id}})
          </div>
          <div v-else>-</div>
        </span>
        <span slot="time" slot-scope="update_time">
          {{update_time | time}}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <!-- <a @click="handleEdit(record)" v-if="record.audit_status==1" v-action:audit>处理</a>
						<a @click="handleWatch(record)" v-else v-action:view>查看</a>
						<a-divider type="vertical" />
						<a @click="handlePre(record)" v-action:preview>预览</a> -->
            <template v-if="record.audit_status==1">
              <span class="btn_pass" v-action:audit @click="handleEdit(record,2)">通过</span>
              <span class="btn_reject" v-action:audit @click="handleEdit(record,3)">拒绝</span>
            </template>
            <span class="btn_btn" @click="handleWatch(record)" v-action:info>详情</span>
            <span class="btn_btn" @click="printFile(record)" v-action:print>打印</span>
            <a-tooltip v-if="record.audit_status==3">
              <template slot="title">
                {{record.audit_opinion}}
              </template>
              <span class="btn_btn">拒绝理由</span>
            </a-tooltip>
          </template>
        </span>
      </s-table>

      <!-- 详情 -->
      <a-drawer class="myDrawer" title="业务办理详情" width="500px" placement="right" :visible="visible" @close="onClose">
        <div class="drawer_main">
          <div class="drawer_main_title">{{$sys.basic}}</div>
          <div class="drawer_main_content">
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">业务编号</div>
              <div class="drawer_main_item_r">{{info&&info.business_code||'-'}}</div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">联系人</div>
              <div class="drawer_main_item_r">{{info&&info.name||'-'}}</div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">联系人电话</div>
              <div class="drawer_main_item_r">{{info&&info.phone||'-'}}</div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">缴费凭证</div>
              <div class="drawer_main_item_r" style="display: flex;flex-wrap: wrap;">
                <img :src="getImgUrl(info&&info.attachment['0-1'])" alt="" width="120px" preview="1" style="cursor:pointer;margin-bottom:10px;margin-right: 10px;" v-if="info&&info.attachment['0-1']">
                <img :src="getImgUrl(info&&info.attachment['0-2'])" alt="" width="120px" preview="1" style="cursor:pointer;margin-bottom:10px;margin-right: 10px;" v-if="info&&info.attachment['0-2']">
                <img :src="getImgUrl(info&&info.attachment['0-3'])" alt="" width="120px" preview="1" style="cursor:pointer;margin-bottom:10px;margin-right: 10px;" v-if="info&&info.attachment['0-3']">
                <img :src="getImgUrl(info&&info.attachment['0-4'])" alt="" width="120px" preview="1" style="cursor:pointer;margin-bottom:10px;margin-right: 10px;" v-if="info&&info.attachment['0-4']">
                <img :src="getImgUrl(info&&info.attachment['0-5'])" alt="" width="120px" preview="1" style="cursor:pointer;margin-bottom:10px;margin-right: 10px;" v-if="info&&info.attachment['0-5']">
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">审核状态</div>
              <div class="drawer_main_item_r a_pass" v-if="info&&info.audit_status==2">审核通过</div>
              <div class="drawer_main_item_r a_reject" v-else-if="info&&info.audit_status==3">审核拒绝</div>
              <div class="drawer_main_item_r" v-else>-</div>
            </div>
            <div class="drawer_main_item" v-if="info&&info.audit_status==3">
              <div class="drawer_main_item_l">{{$sys.auditReason}}</div>
              <div class="drawer_main_item_r">{{info&&info.audit_opinion||'-'}}</div>
            </div>
          </div>
          <div class="drawer_main_title">户号信息</div>
          <div class="drawer_main_content">
            <div class="drawer_main_item" style="flex-direction: column;">
              <div class="acc_list" v-for="(item, index) in info.data" :key="index">
                <div style="display: flex;justify-content: space-between;">
                  <div>{{item.customer_name}} ({{item.customer_id}})</div>
                  <div>
                    <span v-show="item.sumPay>0">欠费金额:¥{{item.sumPay}}</span>
                    <span v-show="item.deposit>0" style="margin-left: 10px;">账户余额:{{item.deposit}}</span>
                  </div>
                </div>
                <div style="margin-top: 5px;">
                  <span v-if="info&&info.data_source==3">账务月份:{{item.month.replace(/\|/g,'-')}}</span>
                  <span v-else>账务月份:{{item.month}}</span>
                </div>
                <div style="margin-top: 5px;">
                  <span>账务金额:¥{{item.FactFee}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="drawer_main_title">开票信息</div>
          <template v-if="info.bill_type">
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">开票方式</div>
              <div class="drawer_main_item_r">
                {{info.bill_type==1?'合并开票':'分开开票'}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">企业名称</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.company_name}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">企业税号</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.company_code}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">企业地址</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.company_addr}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">企业电话</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.company_phone}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">开户银行</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.bank}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">银行账号</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.bank_account}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">领取方式</div>
              <div class="drawer_main_item_r">
                {{info.type==1?'线下自取':'邮政到付'}}
              </div>
            </div>
            <div class="drawer_main_item" v-if="info.attachment&&info.attachment['0-6']">
              <div class="drawer_main_item_l">开票信息确认表</div>
              <div class="drawer_main_item_r" style="flex-direction: column;">
               <img :src="getImgUrl(info&&info.attachment['0-6'])" alt="" width="120px" preview="6" style="cursor:pointer;margin-bottom:10px;">
              </div>
            </div>
          </template>
          <template v-if="info.type==2">
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">收货人</div>
              <div class="drawer_main_item_r">
                {{info.express_info.name}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">联系电话</div>
              <div class="drawer_main_item_r">
                {{info.express_info.phone}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">收货地址</div>
              <div class="drawer_main_item_r">
                {{info.express_info.name1}}{{info.express_info.name2}}{{info.express_info.name3}}{{info.express_info.receiveAddress}}
              </div>
            </div>
            <div class="drawer_main_item">
              <div class="drawer_main_item_l">开票单位名称</div>
              <div class="drawer_main_item_r">
                {{info.bill_info.company_name}}
              </div>
            </div>
            <div class="drawer_main_item" v-if="info.express_number">
              <div class="drawer_main_item_l">快递单号</div>
              <div class="drawer_main_item_r">
                {{info.express_number}}
              </div>
            </div>
          </template>
        </div>
      </a-drawer>
      <!-- 理由 -->
      <a-modal class="modal_foot_center" v-model="modalVisible" title="理由" centered @ok="closeModal(1)" @cancel="closeModal(0)">
        <div>
          <a-textarea placeholder="请输入回复内容" :rows="6" v-model="reason" />
        </div>
      </a-modal>
    </a-card>
    <iframe id="frame" :src="iframeUrl" frameborder="0" ref="iframe" style="display:none"></iframe>
  </page-header-wrapper>
</template>

<script>
  import moment from 'moment'
  import {
    getUrlKey
  } from '@/utils'
  import {
    Modal,
    message
  } from 'ant-design-vue'
  import {
    STable,
    Ellipsis
  } from '@/components'
  import {
    invoApplyList,
    invoApplyInfo,
    handleInvoApply
  } from '@/api/service'
  import workbenchTab from '../service/workbenchTab'
  import {
    prefix,
    getSource,
    checkPrefixWithHttp
  } from '@/utils'
  const columns = [{
      title: '序号',
      scopedSlots: {
        customRender: 'serial'
      },
      width: '90px',
      align: 'center'
    },
    {
      title: '数据来源',
      dataIndex: 'data_source',
      scopedSlots: {
        customRender: 'source'
      },
      align: 'center',
      width: '100px'
    },
    {
      title: '状态',
      dataIndex: 'audit_status',
      scopedSlots: {
        customRender: 'status'
      },
      align: 'center',
      width: '120px'
    },
    {
      title: '业务编号',
      dataIndex: 'business_code',
      scopedSlots: {
        customRender: 'business_code'
      },
      align: 'center',
      width: '155px'
    },
    {
      title: '联系人',
      dataIndex: 'name',
      scopedSlots: {
        customRender: 'name'
      },
      align: 'center',
      width: '100px'
    },
    {
      title: '联系人电话',
      dataIndex: 'phone',
      scopedSlots: {
        customRender: 'phone'
      },
      align: 'center',
      width: '130px'
    },
    {
      title: '缴费凭证',
      dataIndex: '',
      scopedSlots: {
        customRender: 'attachment'
      },
      align: 'center',
      width: '120px'
    },
    {
      title: '领取方式',
      dataIndex: '',
      scopedSlots: {
        customRender: 'method'
      },
      align: 'center',
      width: '180px'
    },
    {
      title: '户号信息',
      dataIndex: '',
      scopedSlots: {
        customRender: 'info'
      },
      align: 'center',
      'width': '180px'
    },
    {
      title: '提交时间',
      dataIndex: 'create_time',
      scopedSlots: {
        customRender: 'time'
      },
      align: 'center',
      width: '180px'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '225px',
      scopedSlots: {
        customRender: 'action'
      },
      align: 'center'
    }
  ]

  const statusMap = {
    0: {
      status: 'default',
      text: '全部'
    },
    1: {
      status: 'default',
      text: '待审核'
    },
    2: {
      status: 'success',
      text: '审核通过'
    },
    3: {
      status: 'error',
      text: '审核拒绝'
    }
  }

  export default {
    name: '',
    components: {
      STable,
      Ellipsis,
      workbenchTab
    },
    data() {
      this.columns = columns
      return {
        visible: false,
        advanced: false,
        // 查询参数
        queryParam: {
          time: [],
          auditStatus: '0',
          keyword: '',
          keyType: '3',
          startTime: '',
          endTime: '',
          worker: '',
          dataSource: '0',
          type: '0'
        },
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          const requestParameters = Object.assign({}, parameter, this.queryParam)
          return invoApplyList(requestParameters)
            .then(res => {
              return res.data
              setTimeout(_ => {
                this.$previewRefresh()
              }, 1000)
            })
        },
        active: 0,
        modalVisible: false, //回复弹窗
        reason: '', //审核理由
        statusMap: statusMap,
        info: '', //详情
        id: '', //列表id
        status: '', //2 通过  3 拒绝
        isChecked: false,
        prefix: prefix,
        customerId: '', //户号
        pageNum: 0,
        iframeUrl: '',
      }
    },
    filters: {
      time(t) {
        if (t) {
          return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
        } else {
          return t
        }
      },
      statusFilter(type) {
        return statusMap[type].text
      },
      statusTypeFilter(type) {
        return statusMap[type].status
      }
    },
    created() {
      this.pageNum = Number(getUrlKey('p') || 0);
    },
    mounted() {

    },
    methods: {
      checkPrefixWithHttp: checkPrefixWithHttp,
      getSource: getSource,
      getUrl(obj){
        for(let i in obj){
          //console.log(obj[i]);
          if(obj[i]){
            return obj[i];
            break;
          }
        }
      },
      getImgUrl(url){
        return checkPrefixWithHttp(url)?url:prefix+url
      },
      getTotalMoney(data) {
        let total = 0;
        data && data.map(item => {
          total += Number(item.sumPay)
        })
        return total;
      },
      printFile(item) { //点击打印
        let url = process.env.VUE_APP_PRINT_URL+'/h5/invoApply.html?id=' + item.id + '&token=' + localStorage
          .getItem('Access-Token') + '&v=' + Date.parse(new Date());
        this.iframeUrl = process.env.VUE_APP_PRINT_URL+'/h5/invoApply.html?id=';
        this.$nextTick(_ => {
          this.iframeUrl = url;
        })
        setTimeout(_ => {
          var int = null;
          var count = 0;
          let img = null;
          try {
            img = document.getElementById('frame').contentWindow.document.getElementById('img0')||document.getElementById('frame').contentWindow.document.getElementById('img1')
            ||document.getElementById('frame').contentWindow.document.getElementById('img2')||document.getElementById('frame').contentWindow.document.getElementById('img3')||document.getElementById('frame').contentWindow.document.getElementById('img4')
          } catch (err) {
            img = null;
          }
          if (img) {
            let iframe = document.getElementById('frame');
            iframe.contentWindow.print();
            img = null
          } else {
            int = setInterval(_ => {
              try {
                img = document.getElementById('frame').contentWindow.document.getElementById('img0')||document.getElementById('frame').contentWindow.document.getElementById('img1')
            ||document.getElementById('frame').contentWindow.document.getElementById('img2')||document.getElementById('frame').contentWindow.document.getElementById('img3')||document.getElementById('frame').contentWindow.document.getElementById('img4') || null;
                if (img) {
                  clearInterval(int);
                  let iframe = document.getElementById('frame');
                  iframe.contentWindow.print();
                  img = null
                } else {
                  count++;
                  if (count >= 10) {
                    clearInterval(int);
                    img = null
                  }
                }
              } catch (err) {
                clearInterval(int);
                img = null
              }
            }, 500)
          }
        }, 1000)
      },
      reply() { //回复弹窗打开
        this.modalVisible = true;
      },
      closeModal(status) { //回复弹窗关闭 1确定 2取消
        //console.log('status',status)
        let that = this
        let reason = this.reason
        let params = {
          auditOpinion: reason,
          id: this.id,
          auditStatus: 3,
          customerId: this.customerId
        }
        if (status == 1) {
          if (!reason) {
            this.$message.error('请输入拒绝理由')
            return false
          }
          Modal.confirm({
            content: '确认拒绝吗?',
            onOk() {
              handleInvoApply(params).then(res => {
                if (res.code == 200) {
                  message.success('处理成功')
                  that.visible = false
                  that.$refs.table.refresh()
                  that.reason = ''
                  that.id = ''
                  that.status = ''
                  that.customerId = ''
                  that.modalVisible = false;
                } else {
                  message.error(res.msg)
                }

              })

            },
            onCancel() {
              that.modalVisible = false
            }
          })
        }
      },
      getTabId(e) {
        if (e == 0) {
          this.queryParam.worker = '';
        } else if (e == 1) {
          this.queryParam.worker = 'A';
        } else if (e == 2) {
          this.queryParam.worker = 'B';
        }
        this.$refs.table.refresh(true)
      },
      getDate(date, dateString) { //时间筛选
        this.queryParam.time = dateString
        this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0] + ' 00:00:00' : ''
        this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1] + ' 23:59:59' : ''
      },
      getStatus(e) {
        this.auditStatus = e;
      },
      getTpye(e) {
        this.queryParam.type = e;
      },
      reset() { //重置
        this.queryParam.time = null
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
        this.queryParam.auditStatus = '0'
        this.queryParam.keyword = ''
        this.queryParam.keyType = '3'
        this.queryParam.dataSource = '0'
        this.queryParam.type = '0'
        this.$refs.table.refresh(true)
      },
      handleEdit(record, status) { //处理
        this.id = record.id;
        this.customerId = record.customer_id;
        this.onClose(status);
      },
      handleWatch(record) {
        this.id = record.id
        this.getDetail(record.id)
        this.isChecked = true;
        this.visible = true
      },
      handlePre(record) { //预览
        let url = this.prefix + '/public/h5/transfer_unit.html?id=' + record.id + '&token=' + localStorage.getItem('Access-Token')
        window.open(url);
      },
      getDetail(id) { //详情
        let params = {
          id: id
        }
        invoApplyInfo(params).then(res => {
          if (res.code == 200) {
            this.info = res.data;
            this.$previewRefresh();
          } else {
            message.error(res.msg)
          }
        })
      },
      onClose(status) { //关闭右侧弹出层   2 通过  3 拒绝
        if (status == 2) {
          let that = this
          let reason = this.reason
          let params = {
            auditOpinion: reason,
            id: this.id,
            auditStatus: 2,
            customerId: this.customerId
          }
          Modal.confirm({
            content: '确认通过吗?',
            onOk() {
              handleInvoApply(params).then(res => {
                if (res.code == 200) {
                  message.success('处理成功')
                  that.visible = false
                  that.$refs.table.refresh()
                  that.reason = ''
                  that.id = ''
                  that.status = ''
                  that.customerId = ''
                  that.modalVisible = false;
                } else {
                  message.error(res.msg)
                }
              })
            },
            onCancel() {
              that.modalVisible = false
            }
          })
        } else if (status == 3) {
          this.modalVisible = true;
        } else {
          this.visible = false
        }
      },
      exportData() {
        const url = process.env.VUE_APP_API_BASE_URL + 'newAdmin.php?c=Jmh&a=exportFp&auditStatus=' + this.queryParam
          .auditStatus + '&keyword=' + this.queryParam.keyword + '&worker=' + this.queryParam.worker + '&dataSource=' +
          this.queryParam.dataSource + '&type=' + this.queryParam.type + '&startTime=' + (this.queryParam.time[0] ? this
            .queryParam.time[0] + ' 00:00:00' : '') + '&endTime=' + (this.queryParam.time[1] ? this.queryParam.time[1] +
            ' 23:59:59' : '');
        window.open(url);
      }

    }
  }
</script>

<style scoped>
  .acc_list {
    background: #F9FCFF;
    border-radius: 4px;
    border: 1px solid #C3E5FF;
    margin-bottom: 15px;
    padding: 10px;
  }

  .btn_excel {
    width: 78px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .btn_excel img {
    width: 18px;
    margin-right: 5px;
  }
</style>
